import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy, filter } from 'lodash';
import { canUseDOM } from 'exenv';

import loadable from '@loadable/component';
import { UserConsumer } from '../../../components/context/userContext';
import BlueConicPremiumPaywall from '../../../components/BlueConicPremiumPaywall/BlueConicPremiumPaywall';
import clsx from 'clsx';

const Social = loadable(() => import('../../../components/social/social'));

/* eslint-disable camelcase, array-callback-return, no-param-reassign, consistent-return, react/no-did-mount-set-state */

export default class BWI2020 extends Component {
  constructor(props) {
    super(props);

    this.intro = null;
    this.mobileException = null;

    // Functions
    this.changeTab = this.changeTab.bind(this);
    this.processInitialData = this.processInitialData.bind(this);
    this.addZeroes = this.addZeroes.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.expandDetails = this.expandDetails.bind(this);
    this.showMobileShareButtons = this.showMobileShareButtons.bind(this);
    this.shouldRenderRow = this.shouldRenderRow.bind(this);
    this.determineIfMobile = this.determineIfMobile.bind(this);

    this.state = {
      activeTab: 1,
      activeFilter: 'rank',
      activeFilterDirection: 'asc',
      list_data_1: [],
      list_data_2: [],
      currentListSorted: [],
      expandedRow: null,
      showMobileShare: false,
      isMobile: false
    };
  }

  componentDidMount() {
    this.intro = this.props.posts[0].content;
    this.processInitialData();

    if (canUseDOM) {
      const mql = window.matchMedia('(max-width: 699px)');
      mql.addListener(this.determineIfMobile);

      this.setState(
        {
          isMobile: window.innerWidth < 700
        },
        () => {
          if (window.location.search.includes('best-teams')) {
            this.changeTab(2);
          }
        }
      );
    }
  }

  changeTab(tab) {
    if (tab !== this.state.activeTab) {
      this.setState(
        {
          activeTab: tab,
          activeFilterDirection: 'asc'
        },
        () => {
          this.expandDetails(null, null);

          if (tab === 1) {
            this.setState({
              currentListSorted: this.state.list_data_1,
              activeFilter: 'rank'
            });
          } else if (tab === 2) {
            this.setState({
              currentListSorted: sortBy(this.state.list_data_2, [
                o => o.company_name
              ]),
              activeFilter: 'company_name'
            });
          }
        }
      );
    }
  }

  processInitialData() {
    const list_data_1 = [];
    const list_data_2 = [];

    this.props.posts[0].customFields.best_workplaces_for_innovators.list_data_1.map(
      (row, i) => {
        row.rank = i + 1;
        row.company_name = row.company_name.replace('&amp;', '&');
        list_data_1.push(row);
      }
    );

    this.props.posts[0].customFields.best_workplaces_for_innovators.list_data_2.map(
      (row, i) => {
        row.rank = i + 1;
        row.company_name = row.company_name.replace('&amp;', '&');
        list_data_2.push(row);
      }
    );

    this.setState({
      activeTab: 1,
      list_data_1,
      list_data_2,
      currentListSorted: list_data_1
    });
  }

  addZeroes(number) {
    if (number < 10) {
      return `00${number}`;
    } else if (number < 100) {
      return `0${number}`;
    }
    return number;
  }

  handleFilter(sortFilter) {
    let listData = [];

    if (sortFilter !== this.state.activeFilter) {
      this.setState(
        {
          activeFilter: sortFilter,
          activeFilterDirection: 'asc'
        },
        () => {
          switch (this.state.activeTab) {
            case 1:
              listData = sortBy(this.state.list_data_1, [o => o[sortFilter]]);

              this.setState({ currentListSorted: listData });

              break;

            case 2:
              if (sortFilter === 'recognition') {
                const winners = filter(this.state.list_data_2, {
                  recognition: 'Winner'
                });
                const finalists = filter(this.state.list_data_2, {
                  recognition: 'Finalist'
                });
                const mentions = filter(this.state.list_data_2, {
                  recognition: 'Honorable Mention'
                });

                const sortedWinners = sortBy(winners, ['company_name']);
                const sortedFinalists = sortBy(finalists, ['company_name']);
                const sortedMentions = sortBy(mentions, ['company_name']);

                sortedWinners.map(entry => {
                  listData.push(entry);
                });

                sortedFinalists.map(entry => {
                  listData.push(entry);
                });

                sortedMentions.map(entry => {
                  listData.push(entry);
                });
              } else {
                listData = sortBy(this.state.list_data_2, [o => o[sortFilter]]);
              }

              this.setState({ currentListSorted: listData });

              break;

            default:
              return false;
          }
        }
      );
    } else {
      this.setState(
        {
          activeFilterDirection:
            this.state.activeFilterDirection === 'asc' ? 'desc' : 'asc'
        },
        () => {
          switch (this.state.activeTab) {
            case 1:
              listData = sortBy(this.state.list_data_1, [o => o[sortFilter]]);

              if (this.state.activeFilterDirection === 'desc') {
                listData.reverse();
              }

              this.setState({ currentListSorted: listData });

              break;

            case 2:
              if (sortFilter === 'recognition') {
                const winners = filter(this.state.list_data_2, {
                  recognition: 'Winner'
                });
                const finalists = filter(this.state.list_data_2, {
                  recognition: 'Finalist'
                });
                const mentions = filter(this.state.list_data_2, {
                  recognition: 'Honorable Mention'
                });

                const sortedWinners = sortBy(winners, ['company_name']);
                const sortedFinalists = sortBy(finalists, ['company_name']);
                const sortedMentions = sortBy(mentions, ['company_name']);

                sortedWinners.map(entry => {
                  listData.push(entry);
                });

                sortedFinalists.map(entry => {
                  listData.push(entry);
                });

                sortedMentions.map(entry => {
                  listData.push(entry);
                });
              } else {
                listData = sortBy(this.state.list_data_2, [o => o[sortFilter]]);
              }

              if (this.state.activeFilterDirection === 'desc') {
                listData.reverse();
              }

              this.setState({ currentListSorted: listData });

              break;

            default:
              return false;
          }
        }
      );
    }
  }

  expandDetails(row) {
    if (this.state.activeTab === 1) {
      this.setState({
        expandedRow: row !== this.state.expandedRow ? row : null
      });
    }
  }

  showMobileShareButtons() {
    this.setState({
      showMobileShare: this.state.showMobileShare !== true
    });
  }

  shouldRenderRow(row) {
    if (this.state.isMobile) {
      switch (this.state.activeTab) {
        case 1:
          return row.company_name !== this.state.list_data_1[0].company_name;
        case 2:
          return row.company_name !== this.state.list_data_2[0].company_name;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  determineIfMobile(e) {
    this.setState({
      isMobile: e.matches
    });
  }

  render() {
    return (
      <main className="bwi-2020">
        <header className="header-image">
          <object
            className="desktop"
            data="https://assets.fastcompany.com/asset_files/static/svg/fc-bwfi2020-desktop.svg"
            aria-label="Best Workplaces for Innovators 2020"
          />
          <img
            className="mobile"
            src="https://images.fastcompany.net/image/upload/v1595007525/fcweb/bwfi2020-landerhero-mobile_2x_2x_deoonl.png"
            alt="Best Workplaces for Innovators 2020"
          />
        </header>

        <div
          className={`social-buttons ${
            this.state.showMobileShare ? 'active' : 'inactive'
          }`}
        >
          <Social
            enableSticky={false}
            empty={false}
            topValue={this.props.fullPageAdvertorial ? 160 : 111}
            text={encodeURIComponent(get(this.props.posts[0], 'title') || '')}
            uri={
              this.props.posts[0].isNewUrl
                ? get(this.props.posts[0], 'newUrl', '')
                : this.props.posts[0].link
            }
            modifier="post"
            bottomBoundary={`#social--${this.props.posts[0].id}`}
            encapsulateId={`social--${this.props.posts[0].id}`}
            active={true}
            {...this.props}
          />
        </div>

        <section className="intro">
          <img
            src="https://images.fastcompany.net/image/upload/v1594760250/fcweb/triangle-dropcap_u3ydhp.svg"
            alt=""
          />
          <span dangerouslySetInnerHTML={{ __html: this.intro }} />
        </section>

        <section className="list-container">
          <nav className="tabs">
            <div
              className={`tab ${
                this.state.activeTab === 1 ? 'active' : 'inactive'
              }`}
              onClick={() => this.changeTab(1)}
            >
              <div className="tab-title">
                {
                  this.props.posts[0].customFields
                    .best_workplaces_for_innovators.panel_name_1
                }
              </div>
              <div className="tab-title-mobile">Workplaces</div>
              <div className="tab-content">
                <div className="company-info">
                  <div className="award">2020 Best Workplace</div>
                  <div className="company">
                    {
                      this.props.posts[0].customFields
                        .best_workplaces_for_innovators.list_data_1[0]
                        .company_name
                    }
                  </div>
                  <div className="location">
                    {
                      this.props.posts[0].customFields
                        .best_workplaces_for_innovators.list_data_1[0].location
                    }
                  </div>
                </div>

                <div className="illo">
                  <img
                    src="https://images.fastcompany.net/image/upload/v1594775055/fcweb/illo-stjude-01_2x_cfsoy5.png"
                    style={{ maxWidth: '185px' }}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab ${
                this.state.activeTab === 2 ? 'active' : 'inactive'
              }`}
              onClick={() => this.changeTab(2)}
            >
              <div className="tab-title">
                {
                  this.props.posts[0].customFields
                    .best_workplaces_for_innovators.panel_name_2
                }
              </div>
              <div className="tab-title-mobile">Teams</div>
              <div className="tab-content">
                <div className="company-info">
                  <div className="award">2020 Best Team</div>
                  <div className="company">
                    {
                      this.props.posts[0].customFields
                        .best_workplaces_for_innovators.list_data_2[0]
                        .company_name
                    }
                  </div>
                  <div className="location">
                    {
                      this.props.posts[0].customFields
                        .best_workplaces_for_innovators.list_data_2[0].location
                    }
                  </div>
                </div>

                <div className="illo">
                  <img
                    src="https://images.fastcompany.net/image/upload/v1596054137/fcweb/lander-1040x585-best-workplaces-for-innovators-2020-abcellera_1_snifg4.png"
                    style={{ maxWidth: '200px' }}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              className="mobile-share"
              onClick={() => this.showMobileShareButtons()}
            >
              <img
                src="https://images.fastcompany.net/image/upload/v1595012404/fcweb/share_icon_1_llq0lx.svg"
                alt=""
              />
            </div>
          </nav>

          <section className="mobile-list-header">
            <nav className="mobile-filters">
              <span>Sort By</span>
              <select
                onChange={e => this.handleFilter(e.currentTarget.value)}
                value={this.state.activeFilter}
              >
                {this.state.activeTab === 1 && (
                  <option value="rank">Rank</option>
                )}
                <option value="company_name">Company Name</option>
                {this.state.activeTab === 2 && (
                  <option value="recognition">Recognition</option>
                )}
                <option value="location">Location</option>
                <option value="industry">Industry</option>
              </select>
            </nav>

            <div className="mobile-illo">
              {this.state.activeTab === 1 && (
                <img
                  src="https://images.fastcompany.net/image/upload/v1594775055/fcweb/illo-stjude-01_2x_cfsoy5.png"
                  width="239"
                  alt=""
                />
              )}
              {this.state.activeTab === 2 && (
                <img
                  src="https://images.fastcompany.net/image/upload/v1596054137/fcweb/lander-1040x585-best-workplaces-for-innovators-2020-abcellera_1_snifg4.png"
                  width="240"
                  alt=""
                />
              )}
            </div>

            <div
              className={`mobile-top-company ${
                this.state.activeTab === 1 ? 'panel-1' : 'panel-2'
              }`}
            >
              {this.state.activeTab === 1 && <div className="rank">001</div>}
              <div className="award">
                {this.state.activeTab === 1
                  ? '2020 Best Workplace'
                  : '2020 Best Team'}
              </div>

              <div className="company">
                {this.state.activeTab === 1
                  ? this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_1[0]
                      .company_name
                  : this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_2[0]
                      .company_name}
              </div>

              <div className="location">
                {this.state.activeTab === 1
                  ? this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_1[0].location
                  : this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_2[0].location}
              </div>

              <div className="industry">
                {this.state.activeTab === 1
                  ? this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_1[0].industry
                  : this.props.posts[0].customFields
                      .best_workplaces_for_innovators.list_data_2[0].industry}
              </div>

              <div className="content">
                <div className="copy">
                  <a
                    href={
                      this.state.activeTab === 1
                        ? this.props.posts[0].customFields
                            .best_workplaces_for_innovators.list_data_1[0]
                            .article_url
                        : this.props.posts[0].customFields
                            .best_workplaces_for_innovators.list_data_2[0]
                            .article_url
                    }
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </section>

          <UserConsumer>
            {({ hasPaywallAccess }) => {
              const showDrawer = !hasPaywallAccess();
              const currentList = showDrawer
                ? [...this.state.currentListSorted].slice(0, 2)
                : this.state.currentListSorted;

              return (
                <>
                  <div
                    className={clsx(
                      `list panel-${this.state.activeTab}`,
                      showDrawer && 'list-locked'
                    )}
                  >
                    <div className="list-row filters">
                      {this.state.activeTab === 1 && (
                        <div
                          className={`filter rank ${
                            this.state.activeFilter === 'rank'
                              ? `active ${this.state.activeFilterDirection}`
                              : ''
                          }`}
                          onClick={() => this.handleFilter('rank')}
                        >
                          Rank <span />
                        </div>
                      )}

                      {this.state.activeTab === 2 && (
                        <div
                          className={`filter recognition ${
                            this.state.activeFilter === 'recognition'
                              ? `active ${this.state.activeFilterDirection}`
                              : ''
                          }`}
                          onClick={() => this.handleFilter('recognition')}
                        >
                          Recognition <span />
                        </div>
                      )}

                      <div
                        className={`filter company ${
                          this.state.activeFilter === 'company_name'
                            ? `active ${this.state.activeFilterDirection}`
                            : ''
                        }`}
                        onClick={() => this.handleFilter('company_name')}
                      >
                        Company Name <span />
                      </div>

                      <div
                        className={`filter location ${
                          this.state.activeFilter === 'location'
                            ? `active ${this.state.activeFilterDirection}`
                            : ''
                        }`}
                        onClick={() => this.handleFilter('location')}
                      >
                        Location <span />
                      </div>

                      <div
                        className={`filter industry ${
                          this.state.activeFilter === 'industry'
                            ? `active ${this.state.activeFilterDirection}`
                            : ''
                        }`}
                        onClick={() => this.handleFilter('industry')}
                      >
                        Industry <span />
                      </div>
                    </div>

                    {currentList.map((company, idx) => (
                      <React.Fragment>
                        {this.shouldRenderRow(company) && (
                          <div
                            className={`list-row ${
                              this.state.expandedRow === idx ? 'open' : 'closed'
                            } ${
                              company.company_name.toLowerCase() ===
                                'abcellera' && this.state.activeTab === 2
                                ? 'open-by-default'
                                : ''
                            }`}
                            key={`row-${idx}`}
                            onClick={() => this.expandDetails(idx)}
                          >
                            <div className="list-row-container">
                              {this.state.activeTab === 1 && (
                                <div className="column rank">
                                  {this.addZeroes(company.rank)}
                                </div>
                              )}
                              {this.state.activeTab === 2 && (
                                <div className="column recognition">
                                  {company.recognition}
                                </div>
                              )}
                              <div className="column company">
                                {company.company_name}
                              </div>
                              <div className="column location">
                                {company.location}
                              </div>
                              <div className="column industry">
                                {company.industry}
                              </div>

                              {this.state.activeTab === 1 && (
                                <div className="column icon">
                                  <img
                                    src="https://images.fastcompany.net/image/upload/v1594782797/fcweb/X_eci9k7.svg"
                                    alt=""
                                  />
                                </div>
                              )}

                              <div
                                className={`content ${
                                  company.image_url ? 'has-image' : 'no-image'
                                }`}
                              >
                                <div className="copy">
                                  <div
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html: company.company_description
                                    }}
                                  />

                                  {company.article_url && (
                                    <a
                                      className="readmore-button"
                                      href={company.article_url}
                                      target="_blank"
                                    >
                                      Read More
                                    </a>
                                  )}
                                </div>

                                {company.image_url && (
                                  <div className="image">
                                    <img src={company.image_url} alt="" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  {showDrawer && <BlueConicPremiumPaywall />}
                </>
              );
            }}
          </UserConsumer>
        </section>
      </main>
    );
  }
}

BWI2020.propTypes = {
  posts: PropTypes.shape([]).isRequired,
  fullPageAdvertorial: PropTypes.bool.isRequired
};
